import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ButtonsDiv,
  CashbackAvailableDiv,
  CashbackSeparator,
  InputContainer,
  NotRegisteredClubDiv,
  OtherReviews,
  ReviewForm,
  ReviewsPageContainer,
  SessionDetailDiv,
  SessionTotalDiv,
} from './styles';
import { Button, DefaultInput, LoadingPage } from 'ui-kit-takeat';
import { BackButton } from '../../components/BackButton';
import happyStar from '../../assets/images/happy_star.svg';
import thankRating from '../../assets/images/thank_rating.svg';
import ReactStars from 'react-rating-stars-component';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { ReviewItem } from './ReviewItem';
import { AnimatePresence } from 'framer-motion';
import ReactInputMask from 'react-input-mask';
import api from '../../services/api';
import { useAuth } from '../../context/auth';
import { toast } from 'react-toastify';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { formatPrice } from '../../utils/formatPrice';
import ModalInfo from '../../components/ClubRegisterComponents/ModalInfo';
import celebrationImg from '../../assets/images/Celebration.svg';
import { ClubFormContext } from '../../context/clubForm';
import { apiClub } from '../../services/apiClub';
import { ModalCashback } from '../../components/ModalCashback';
import { ModalBody, ModalFooter } from 'reactstrap';
import {
  ForgotPass,
  PhoneDisplay,
} from '../../components/ModalCashback/styles';
import { maskString } from '../../utils/functions';

const RATING_OPTIONS = [
  { type: 'food', text: 'Comida' },
  { type: 'service', text: 'Serviço' },
  { type: 'price', text: 'Preço' },
  { type: 'enviroment', text: 'Ambiente' },
];

const NOT_CLOSING_BILL = ['welcome', 'menu', 'club'];

export const ReviewsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const from = location.state?.from;
  const isClosingBill = !NOT_CLOSING_BILL.includes(from);

  const { getFormValue } = useContext(ClubFormContext);
  const phone = getFormValue('phone');
  const name = getFormValue('name');

  const [formStep, setFormStep] = useState(isClosingBill ? 1 : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSuccessCashback, setModalSuccessCashback] = useState(false);
  const [modalRelate, setModalRelate] = useState(false);
  const [modalForgotPassword, setModalForgotPassword] = useState(false);

  const [loading, setLoading] = useState({
    login: false,
    sendReview: false,
    clubRelate: false,
    checkBirthday: false,
    clientCashback: false,
    rescueCashback: false,
    forgotPassword: false,
  });

  const updateLoading = (key, value) => {
    setLoading(state => ({ ...state, [key]: value }));
  };

  const [sessionDetails, setSessionDetails] = useState(null);
  const {
    userPhone,
    userName,
    userLogin,
    userToken,
    restaurantId,
    restaurantName,
    tableKey,
    formatValue,
    sessionKey,
    setSessionKey,
    hasClube,
    keyClube,
    clube,
  } = useAuth();

  const [clientData, setClientData] = useState({
    phone: userPhone,
    name: userName,
    password: '',
    birthday: '',
    birthday_confirmed: false,
  });

  const [ratings, setRatings] = useState({
    experience: 0,
  });

  const [comment, setComment] = useState('');

  const clientLogin = useCallback(async () => {
    if (
      !userToken ||
      clientData.phone !== userPhone ||
      clientData.name !== userName
    ) {
      updateLoading('login', true);
      await userLogin(clientData.phone, clientData.name);
      updateLoading('login', false);

      setFormStep(1);
    } else {
      setFormStep(1);
    }
  }, [clientData]);

  const getSessionDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/client/sessions/details`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          restaurant_name: restaurantName,
          table_code: tableKey,
        },
      });

      setSessionDetails(data);

      if (!data.session) {
        setSessionKey(null);
      } else {
        setSessionKey(data.session.key);
      }

      // if (data.session && userPhone) {
      //   setFormStep(1);
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Error getting session details', error);
    }
  };

  const sendReview = async () => {
    const data = {
      stars: ratings.experience,
      ratings: RATING_OPTIONS.filter(option => ratings[option.type] > 0).map(
        option => {
          return {
            adjective: option.type,
            stars: ratings[option.type],
          };
        },
      ),
      restaurant_id: restaurantId,
      comment: comment,
      table_code: tableKey,
      session_key: sessionKey,
    };

    updateLoading('sendReview', true);

    try {
      await api.post('/client/ratings-2024', data);

      setFormStep(2);
    } catch (err) {
      if (err.response.data.type === 'rating_already_exists') {
        toast.error('Já existe uma avaliação feita por esse número.');
      } else {
        console.log('Send review error: ', err);
        toast.error('Erro ao enviar avaliação. Tente novamente mais tarde.');
      }
    }
    updateLoading('sendReview', false);
  };

  const [clientCashback, setClientCashback] = useState({
    loaded: false,
    value: null,
  });

  const splitEmail = clientCashback.email?.split('@');

  const clubEmail = splitEmail
    ? maskString(splitEmail[0]) + `@${splitEmail[1]}`
    : '';

  const getClientCashback = async () => {
    updateLoading('clientCashback', true);
    try {
      const res = await axios.get(
        `https://backend.clubecliente.com/public/clients/${clientData.phone.replace(
          /(-|\s)/gi,
          '',
        )}/${keyClube}`,
      );

      setClientCashback({
        loaded: true,
        value: res.data.totalClientCashback,
        isRegistered: res.data.clientExist,
        isRelated: res.data.clientBelongsToStore,
        email: res.data.clientEmail,
      });
    } catch (err) {
      console.log('Error getting client cashback', err);
    }
    updateLoading('clientCashback', false);
  };

  const handleRedirect = () => {
    if (from === 'welcome' || from === 'club') {
      history.push(`/auto/${restaurantName}/${tableKey}`);
    } else if (from === 'menu') {
      history.push(`/menu/${restaurantId}`);
    } else {
      history.push('/');
    }
  };

  const checkBirthday = async () => {
    updateLoading('checkBirthday', true);
    try {
      const { data } = await api.post('public/clube/confirm-birthday', {
        phone: clientData.phone.replace(/(-|\s)/g, ''),
        birthday: clientData.birthday,
        restaurant_id: restaurantId,
      });

      if (data.success) {
        setClientData(state => ({
          ...state,
          birthday_confirmed: true,
        }));
      }
      if (data.error) {
        toast.error(data.error);
        setClientData(state => ({
          ...state,
          birthday_confirmed: false,
        }));
      }
    } catch (err) {
      console.log('Error checkBirthday: ', err);
      toast.error(
        'Erro ao checar data de nascimento. Tente novamente em alguns minutos.',
      );
    }
    updateLoading('checkBirthday', false);
  };

  const loginAndRelate = async () => {
    updateLoading('clubRelate', true);
    let loginResponse;
    try {
      const { data } = await apiClub.post('public/sessions/client', {
        ...clientData,
        phone: clientData.phone.replace(/(-|\s)/g, ''),
      });

      loginResponse = data;
    } catch (err) {
      console.log('login club error: ', err);
      toast.error('Erro ao logar no clube, tente novamente');
    }
    try {
      await apiClub.post(
        '/client/create-relation',
        {
          key: clube?.key,
        },
        {
          headers: {
            Authorization: `Bearer ${loginResponse.token}`,
          },
        },
      );

      setModalRelate(false);
      setClientCashback(state => ({
        ...state,
        isRelated: true,
      }));
      toast.success(`Agora você participa do clube ${clube?.name}!`);
    } catch (err) {
      console.log('relate club error: ', err);
      toast.error('Erro ao relacionar ao clube, tente novamente');
    }

    updateLoading('clubRelate', false);
  };

  const rescueCashback = async () => {
    updateLoading('rescueCashback', true);
    try {
      const { data } = await api.post('client/clube/rescue', {
        session_id: sessionDetails?.session?.id,
        birthday: clientData?.birthday,
      });

      console.log('rescueCashback data: ', data);
      setModalSuccessCashback(true);
    } catch (err) {
      console.log('Error rescueCashback: ', err);
      toast.error('Erro ao resgatar cashback, tente novamente');
    }
    updateLoading('rescueCashback', false);
  };

  const sendForgotPassword = async () => {
    updateLoading('forgotPassword', true);
    try {
      const formattedPhone = clientData?.phone?.replace(/(\s|-)/g, '');
      await apiClub.post(`/public/request-change-password/${formattedPhone}`);

      toast.success('Email para redefinição de senha enviado com sucesso!');
      setModalForgotPassword(false);
    } catch (err) {
      console.log('sendForgotPassword: ', err);
      toast.error(
        'Erro ao enviar email de redefinição de senha. Tente novamente mais tarde',
      );
    }
    updateLoading('forgotPassword', false);
  };

  useEffect(() => {
    if (hasClube && keyClube) {
      getClientCashback();
    }
  }, []);

  useEffect(() => {
    if (hasClube && keyClube && clientData.phone?.length === 15) {
      getClientCashback();
    }
  }, [clientData.phone]);

  useEffect(() => {
    if (userToken && !isClosingBill) {
      getSessionDetails();
    }
  }, [userToken]);

  const showClubInfo =
    // Restaurante tem clube
    hasClube &&
    // Carregou o cashback do clube
    clientCashback.loaded &&
    // O cliente tem cashback
    !!clientCashback.value &&
    // O cashback é maior que zero
    parseFloat(clientCashback.value || 0) > 0;

  useEffect(() => {
    if (formStep === 2 && (!showClubInfo || !sessionDetails?.session)) {
      setTimeout(() => {
        handleRedirect();
      }, 2000);
    }
  }, [formStep, clientCashback]);

  useEffect(() => {
    if (phone) {
      setClientData(state => ({
        ...state,
        phone,
      }));
    }
    if (name) {
      setClientData(state => ({
        ...state,
        name,
      }));
    }
  }, [phone, name]);

  useEffect(() => {
    if (clientData.birthday?.length === 10) {
      checkBirthday();
    }
  }, [clientData.birthday]);

  return isLoading ? (
    <LoadingPage duration={3} text="Carregando..." />
  ) : (
    <ReviewsPageContainer>
      {formStep === 0 && (
        <>
          <BackButton>Voltar</BackButton>
          <ReviewForm
            id="testing"
            onSubmit={async data => {
              const values = {
                phone: data.target.phone.value,
                name: data.target.name.value,
              };

              data.preventDefault();
              if (
                !userToken ||
                values.phone !== userPhone ||
                values.name !== userName
              ) {
                setLoading(true);
                await userLogin(
                  data.target.phone.value,
                  data.target.name.value,
                );
                setLoading(false);

                setFormStep(1);
              } else {
                setFormStep(1);
              }
            }}
          >
            <h1>Informe seus dados:</h1>
            <InputContainer>
              <label htmlFor="phone">Celular*</label>
              <ReactInputMask
                type="text"
                placeholder="(XX) XXXXX-XXXX"
                name="phone"
                id="phone"
                mask="(99) 99999-9999"
                maskPlaceholder={null}
                value={clientData.phone}
                onChange={e => {
                  const newState = {
                    name: clientData.name,
                    phone: e.target.value,
                  };
                  setClientData(newState);
                }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                placeholder="Qual o seu nome?"
                name="name"
                value={clientData.name}
                onChange={e => {
                  const newState = {
                    name: e.target.value,
                    phone: clientData.phone,
                  };
                  setClientData(newState);
                }}
              />
            </InputContainer>

            {clientCashback.loaded && !clientCashback.isRelated && (
              <NotRegisteredClubDiv>
                <FiInfo />
                {clientCashback.isRegistered ? (
                  <p>
                    Você já é cadastrado em nosso Clube de Cashback, porém não é
                    vinculado a este restaurante.{' '}
                    <b onClick={() => setModalRelate(true)}>Faça o vinculo</b>{' '}
                    agora e comece a economizar!
                  </p>
                ) : (
                  <p>
                    Ainda não te encontramos em nosso Clube de Cashback.{' '}
                    <b
                      onClick={() =>
                        history.push(
                          `/club/register?phone=${clientData.phone}&name=${clientData.name}`,
                          { from: 'reviews' },
                        )
                      }
                    >
                      Cadastre-se
                    </b>{' '}
                    agora e comece a economizar!
                  </p>
                )}
              </NotRegisteredClubDiv>
            )}
          </ReviewForm>
          <Button
            title="Continuar"
            style={{ borderRadius: 12 }}
            textStyle={{ fontWeight: 700 }}
            disabled={loading.login || loading.clientCashback}
            isLoading={loading.login || loading.clientCashback}
            buttonColor="#c8131b"
            onClick={() => clientLogin()}
          />
          <ModalCashback
            isOpen={modalRelate}
            toggle={() => setModalRelate(false)}
          >
            {modalForgotPassword ? (
              <>
                <ModalBody>
                  <p style={{ margin: 0 }}>
                    Será enviado um link de redefinição via e-mail para o
                    endereço cadastrado no Clube do Cliente de telefone{' '}
                    {clientData.phone}
                  </p>
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    {clubEmail}
                  </span>
                </ModalBody>
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button
                    title="Cancelar"
                    inverted
                    buttonColor="#c8131b"
                    style={{ borderRadius: 12 }}
                    textStyle={{ fontWeight: 700 }}
                    onClick={() => {
                      setModalForgotPassword(false);
                      setModalRelate(false);
                    }}
                    isLoading={loading.forgotPassword}
                    disabled={loading.forgotPassword}
                  />
                  <Button
                    title="Enviar E-mail"
                    buttonColor="#2ec9b7"
                    style={{ borderRadius: 12, flexGrow: 1 }}
                    textStyle={{ fontWeight: 700 }}
                    onClick={() => sendForgotPassword()}
                    isLoading={loading.forgotPassword}
                    disabled={loading.forgotPassword}
                  />
                </div>
              </>
            ) : (
              <>
                <ModalBody>
                  <p style={{ margin: 0 }}>
                    Você não participa desse clube, faça login para participar
                  </p>
                  <PhoneDisplay>{clientData.phone}</PhoneDisplay>
                  <DefaultInput
                    title="Senha"
                    type="password"
                    placeholder="Insira sua senha"
                    value={clientData.password}
                    onChange={e =>
                      setClientData({ ...clientData, password: e.target.value })
                    }
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    title="Login"
                    buttonColor="#2ec9b7"
                    onClick={() => loginAndRelate()}
                    isLoading={loading.clubRelate}
                    disabled={loading.clubRelate}
                    style={{ height: 48 }}
                  />
                  <ForgotPass onClick={() => setModalForgotPassword(true)}>
                    Esqueci minha senha
                  </ForgotPass>
                </ModalFooter>
              </>
            )}
          </ModalCashback>
        </>
      )}
      {formStep === 1 && (
        <>
          {sessionDetails?.session && userPhone ? (
            <BackButton>Voltar</BackButton>
          ) : (
            <BackButton
              onClick={() => {
                setFormStep(0);
                setRatings({
                  experience: 0,
                  food: 0,
                  service: 0,
                  price: 0,
                  enviroment: 0,
                });
              }}
            >
              Voltar
            </BackButton>
          )}
          <ReviewForm>
            {!isClosingBill && (
              <SessionDetailDiv margin={ratings.experience !== 0}>
                {sessionDetails?.session ? (
                  <>
                    <SessionTotalDiv>
                      Total da comanda:{' '}
                      {formatPrice(sessionDetails.session.total_service_price)}
                    </SessionTotalDiv>
                    {showClubInfo && (
                      <CashbackAvailableDiv>
                        <h2>
                          Você possui <b>{formatPrice(clientCashback.value)}</b>{' '}
                          de cashback
                        </h2>
                        <CashbackSeparator />
                        <p>
                          Conclua a avaliação para resgatar ou continuar
                          acumulando.
                        </p>
                      </CashbackAvailableDiv>
                    )}
                  </>
                ) : (
                  <>
                    <SessionTotalDiv style={{ fontSize: 18 }}>
                      Sem comanda aberta
                    </SessionTotalDiv>
                  </>
                )}
              </SessionDetailDiv>
            )}
            <img src={happyStar} style={{ width: 120, margin: '0 auto' }} />
            <h1 style={{ textAlign: 'center' }}>
              Avalie a sua experiência com a gente!
            </h1>
            <ReactStars
              classNames={'stars'}
              count={5}
              value={ratings.experience}
              onChange={e => {
                setRatings(state => {
                  return { ...state, experience: e };
                });
              }}
              size={35}
              activeColor="#FFA814"
              color="#FFA814"
              emptyIcon={<FaRegStar />}
              filledIcon={<FaStar />}
            />
            <AnimatePresence>
              {ratings.experience !== 0 && (
                <OtherReviews
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div>
                    {RATING_OPTIONS.map((option, index) => (
                      <ReviewItem
                        key={index}
                        option={option}
                        value={ratings}
                        setValue={setRatings}
                      />
                    ))}
                  </div>
                  <span>Conte-nos o que podemos melhorar.</span>
                  <textarea
                    placeholder="Descreva aqui seu feedback"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </OtherReviews>
              )}
            </AnimatePresence>
          </ReviewForm>
          <Button
            title="Avaliar"
            disabled={ratings.experience === 0 || loading.sendReview}
            isLoading={loading.sendReview}
            onClick={() => sendReview()}
            buttonColor="#c8131b"
            style={{ borderRadius: 12 }}
            textStyle={{ fontWeight: 700 }}
          />
        </>
      )}
      {formStep === 2 && (
        <>
          <ReviewForm>
            <img
              className="pulse-star"
              src={thankRating}
              style={{ width: 120, margin: '0 auto' }}
            />
            <h1 style={{ textAlign: 'center' }}>
              Obrigado por compartilhar sua opinião conosco!
            </h1>
            {sessionDetails?.session && showClubInfo && (
              <CashbackAvailableDiv>
                <h2>
                  Seu saldo de cashback:{' '}
                  <b>{formatPrice(clientCashback.value)}</b>
                </h2>
                <CashbackSeparator />
                <label>Data de nascimento:</label>
                <DefaultInput
                  mask="99/99/9999"
                  placeholder="dd/mm/aaaa"
                  value={clientData.birthday}
                  onChange={e => {
                    const newState = {
                      ...clientData,
                      birthday: e.target.value,
                    };
                    setClientData(newState);
                  }}
                  style={{ borderRadius: 10 }}
                />
              </CashbackAvailableDiv>
            )}
          </ReviewForm>
          {sessionDetails?.session && showClubInfo && (
            <ButtonsDiv>
              <Button
                title={
                  clientData.birthday?.length === 10
                    ? 'Resgatar cashback'
                    : 'Digite sua data de nascimento'
                }
                disabled={
                  !clientData.birthday_confirmed ||
                  clientData.birthday?.length !== 10 ||
                  loading.rescueCashback
                }
                isLoading={loading.checkBirthday || loading.rescueCashback}
                buttonColor="#2EC9B7"
                style={{ borderRadius: 12 }}
                textStyle={{ fontWeight: 700 }}
                onClick={() => rescueCashback()}
              />
              <Button
                title="Continuar acumulando"
                disabled={loading.rescueCashback}
                isLoading={loading.rescueCashback}
                inverted
                buttonColor="#c8131b"
                style={{ borderRadius: 12 }}
                textStyle={{ fontWeight: 700 }}
                onClick={() => {
                  handleRedirect();
                }}
              />
            </ButtonsDiv>
          )}
          <ModalInfo isOpen={modalSuccessCashback}>
            <img src={celebrationImg} alt="Icone de comemoração" />
            <ModalInfo.Body>
              <span>Resgate concluído com sucesso!</span>
              <p>Quanto mais você compra, mais dinheiro você economiza! 💸</p>
            </ModalInfo.Body>
            <Button
              title="Fechar"
              buttonColor="#2ec9b7"
              onClick={() => {
                handleRedirect();
              }}
            />
          </ModalInfo>
        </>
      )}
    </ReviewsPageContainer>
  );
};
