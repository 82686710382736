import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';
import ReactPixel from 'react-facebook-pixel';
import { FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../../context/auth';

import {
  Container,
  Content,
  Header,
  PaymentsMethods,
  PaymentsMethodTitle,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  Payment,
  PaymentsMethodHeader,
  UserChangeInput,
  CancelButton,
  DeliveryTax,
} from './styles';

import logopicpay from '../../../assets/images/logopicpay.png';
import logocartao from '../../../assets/images/logocartao.png';
import logodinheiro from '../../../assets/images/dinheiro.svg';
import visa from '../../../assets/images/visa.png';
import pix from '../../../assets/images/pix.png';

import masterCard from '../../../assets/images/mastercard.png';
import american from '../../../assets/images/american.png';
import diners from '../../../assets/images/diners.png';
import elo from '../../../assets/images/elo.png';
import hipercard from '../../../assets/images/hipercard.png';
import { useOrder } from '../../../context/order';
import api from '../../../services/api';

const ChoosePayments = () => {
  const {
    setUserPaymentMethod,
    setUserChange,
    userDeliveryType,
    restaurantAcceptsPaytime,
    restaurantAcceptsPix,
    restaurantDeliveryTax,
    isDeliveryByDistance,
    restaurantDeliveryActive,
    restaurantAcceptsDelivery,
    restaurantAcceptsWithdrawal,
    restaurantAcceptsPaymentAtWithdrawal,
    restaurantDeliveryPayments,
    isScheduling,
    restaurantPixelId2,
    restaurantMetaToken2,
    subscriptionIdPixel2,
  } = useAuth();

  const history = useHistory();

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const { ordersTotalValue } = useOrder();

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');
  const [userChangeSet, setUserChangeSet] = useState();

  function handleValidateUSerChange(value) {
    if (restaurantDeliveryTax) {
      if (value <= Number(ordersTotalValue) + Number(restaurantDeliveryTax)) {
        alert('O valor informado é menor ou igual que o valor total do pedido');
      } else {
        setUserChange(userChangeSet);
        history.push('/app/confirm-order-delivery');
        setIsOpen(false);
      }
    } else if (value <= ordersTotalValue) {
      alert('O valor informado é menor ou igual que o valor do produto');
    } else {
      setUserChange(userChangeSet);
      history.push('/app/confirm-order-delivery');
      setIsOpen(false);
    }
  }

  function getPaymentImage(payment_method) {
    const payment = {
      dinheiro: logodinheiro,
      visa_credito: visa,
      visa_debito: visa,
      mastercard_credito: masterCard,
      mastercard_debito: masterCard,
      elo_credito: elo,
      elo_debito: elo,
      hipercard_credito: hipercard,
      hipercard_debito: hipercard,
      diners_club_credito: diners,
      diners_club_debito: diners,
      american_express_credito: american,
      american_express_debito: american,
      pix,
      picpay_offline: logopicpay,
    };

    return payment[payment_method] || logocartao;
  }

  function closeModalWithChange() {
    handleValidateUSerChange(userChangeSet);
  }

  function closeModal() {
    setIsOpen(false);
    history.push('/app/confirm-order-delivery');
    setUserChange(0);
  }

  function openModal() {
    setIsOpen(true);
  }

  // pixel_fb

  const aleatorID = uuid();
  function handleSendDataToPixel(payment_name) {
    if (restaurantPixelId2) {
      ReactPixel.fbq(
        'track',
        'AddPaymentInfo',
        {
          currency: 'BRL',
          payment_method: payment_name,
        },
        {
          eventID: aleatorID,
        },
      );
    }

    if (restaurantPixelId2 && restaurantMetaToken2) {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId2}/events?=${restaurantMetaToken2}`,
        {
          pixel_id: restaurantPixelId2,
          access_token: restaurantMetaToken2,
          data: [
            {
              event_name: 'AddPaymentInfo',
              event_time: Math.floor(Date.now() / 1000),
              action_source: 'website',
              event_id: aleatorID,
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel2,
              },
              custom_data: {
                currency: 'BRL',
                payment_method: payment_name,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    }
  }

  function handleNavigate(e, type, card_flag = '', id, payment_name) {
    handleSendDataToPixel(payment_name);
    if (userDeliveryType.toString() === 'delivery') {
      if (e.id === 'Dinheiro') {
        setUserPaymentMethod({
          type,
          name: 'Dinheiro',
          keyword: card_flag,
          id,
        });

        openModal();
      } else {
        setUserPaymentMethod({
          type,
          name: e.id,
          keyword: card_flag,
          id,
        });
        history.push('/app/confirm-order-delivery');
      }
    } else {
      setUserPaymentMethod({
        type,
        name: e.id,
        keyword: card_flag,
        id,
      });
      history.push('/app/confirm-order-delivery');
    }
  }

  function handleBack() {
    if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      restaurantDeliveryActive.toString() === 'true' &&
      userDeliveryType.toString() === 'delivery' &&
      isDeliveryByDistance.toString() === 'true'
    ) {
      history.push('/app/search-address-by-distance-delivery');
    } else if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      restaurantDeliveryActive.toString() === 'true' &&
      userDeliveryType.toString() === 'delivery'
    ) {
      history.push('/app/search-address-delivery');
    } else if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      userDeliveryType.toString() === 'withdrawal'
    ) {
      history.push('/app/delivery-type');
    } else {
      history.push('/app/cart-delivery');
    }
  }

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Troco pra quanto?</p>
        </ModalTitle>
        <UserChangeInput
          value={userChangeSet}
          onChange={e => {
            setUserChangeSet(e.target.value);
          }}
        />
        <ModalFooter>
          <CancelButton onClick={closeModal}>Não quero troco</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Confirmar
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Content>
        <Header>
          <FiArrowLeft color="var(--primary)" onClick={handleBack} />
          <h1>Pagamento</h1>
        </Header>
        <PaymentsMethods>
          <PaymentsMethodTitle>Como você deseja pagar?</PaymentsMethodTitle>

          {restaurantAcceptsPix.toString() === 'true' &&
            userDeliveryType.toString() === 'delivery' && (
              <>
                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments
                    .filter(
                      item =>
                        item.keyword === 'pix_auto' &&
                        item.restaurant_method[0].delivery_accepts,
                    )
                    .map(method => (
                      <>
                        <PaymentsMethodHeader>
                          <p>Pagar online</p>
                        </PaymentsMethodHeader>
                        <Payment
                          onClick={e => {
                            handleNavigate(
                              e.currentTarget,
                              'online',
                              method.keyword,
                              method.id,
                              'Pix',
                            );
                          }}
                          id="pix_online"
                        >
                          <div>
                            <img src={pix} alt="logopix" />
                          </div>
                          <p>Pix Online</p>
                        </Payment>
                      </>
                    ))}
              </>
            )}

          {restaurantAcceptsPix.toString() === 'true' &&
            userDeliveryType.toString() === 'withdrawal' && (
              <>
                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments

                    .filter(
                      item =>
                        item.keyword === 'pix_auto' &&
                        item.restaurant_method[0].withdrawal_accepts,
                    )
                    .map(method => (
                      <>
                        <PaymentsMethodHeader>
                          <p>Pagar online</p>
                        </PaymentsMethodHeader>
                        <Payment
                          onClick={e => {
                            handleNavigate(
                              e.currentTarget,
                              'online',
                              method.keyword,
                              method.id,
                              'Pix',
                            );
                          }}
                          id="pix_online"
                        >
                          <div>
                            <img src={pix} alt="logopix" />
                          </div>
                          <p>Pix Online</p>
                        </Payment>
                      </>
                    ))}
              </>
            )}

          {restaurantAcceptsPaytime.toString() === 'true' &&
            userDeliveryType.toString() === 'withdrawal' && (
              <>
                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments

                    .filter(
                      item =>
                        item.keyword === 'credit_card_auto' &&
                        item.restaurant_method[0].withdrawal_accepts,
                    )
                    .map(method => (
                      <>
                        <Payment
                          onClick={e => {
                            handleNavigate(
                              e.currentTarget,
                              'online',
                              null,
                              null,
                              'Crédito online',
                            );
                          }}
                          id="credito"
                        >
                          <div>
                            <img
                              src={logocartao}
                              alt="logocartao"
                              style={{ width: '50px' }}
                            />
                          </div>
                          <p>Crédito Online</p>
                        </Payment>
                      </>
                    ))}
              </>
            )}

          {restaurantAcceptsPaytime.toString() === 'true' &&
            userDeliveryType.toString() === 'delivery' && (
              <>
                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments

                    .filter(
                      item =>
                        item.keyword === 'credit_card_auto' &&
                        item.restaurant_method[0].delivery_accepts,
                    )
                    .map(method => (
                      <>
                        <Payment
                          onClick={e => {
                            handleNavigate(
                              e.currentTarget,
                              'online',
                              null,
                              null,
                              'Crédito online',
                            );
                          }}
                          id="credito"
                        >
                          <div>
                            <img
                              src={logocartao}
                              alt="logocartao"
                              style={{ width: '50px' }}
                            />
                          </div>
                          <p>Crédito Online</p>
                        </Payment>
                      </>
                    ))}
              </>
            )}

          {/* {restaurantAcceptsPaytime.toString() === 'true' && (
            <Payment
              onClick={e => {
                handleNavigate(e.currentTarget, 'online');
              }}
              id="credito"
            >
              <div>
                <img
                  src={logocartao}
                  alt="logocartao"
                  style={{ width: '50px' }}
                />
              </div>
              <p>Crédito Online</p>
            </Payment>
          )} */}

          {userDeliveryType.toString() === 'delivery' &&
            restaurantAcceptsDelivery.toString() === 'true' &&
            isScheduling.toString() === 'true' && (
              <>
                <PaymentsMethodHeader>
                  <p>Pagar na entrega</p>
                  {restaurantDeliveryTax && restaurantDeliveryTax > 0 ? (
                    <DeliveryTax>
                      {`Taxa de entrega: R$ ${restaurantDeliveryTax}`}
                    </DeliveryTax>
                  ) : (
                    <DeliveryTax>Entrega grátis</DeliveryTax>
                  )}
                </PaymentsMethodHeader>

                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments
                    .filter(it => it.restaurant_method[0].delivery_accepts)
                    .filter(
                      item =>
                        item.keyword !== 'pix_auto' &&
                        item.keyword !== 'credit_card_auto',
                    )
                    .map(method => (
                      <Payment
                        key={uuid()}
                        onClick={e => {
                          handleNavigate(
                            e.currentTarget,
                            'delivery',
                            method.keyword,
                            method.id,
                            method.name,
                          );
                        }}
                        id={method.name}
                      >
                        <div>
                          <img
                            src={getPaymentImage(method.keyword)}
                            alt={method.name}
                          />
                        </div>

                        <p>{method.name}</p>
                      </Payment>
                    ))}
              </>
            )}

          {userDeliveryType.toString() === 'withdrawal' &&
            restaurantAcceptsWithdrawal.toString() === 'true' &&
            restaurantAcceptsPaymentAtWithdrawal.toString() === 'true' &&
            isScheduling.toString() === 'true' && (
              <>
                <PaymentsMethodHeader>
                  <p>Pagar na retirada</p>
                </PaymentsMethodHeader>

                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments
                    .filter(it => it.restaurant_method[0].withdrawal_accepts)
                    .filter(
                      item =>
                        item.keyword !== 'pix_auto' &&
                        item.keyword !== 'credit_card_auto',
                    )
                    .map(method => (
                      <Payment
                        key={uuid()}
                        onClick={e => {
                          handleNavigate(
                            e.currentTarget,
                            'withdrawal',
                            method.keyword,
                            method.id,
                            method.name,
                          );
                        }}
                        id={method.name}
                      >
                        <div>
                          <img
                            src={getPaymentImage(method.keyword)}
                            alt={method.name}
                          />
                        </div>

                        <p>{method.name}</p>
                      </Payment>
                    ))}
              </>
            )}

          {restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'true' &&
            userDeliveryType.toString() === 'delivery' &&
            isScheduling.toString() === 'false' && (
              <>
                <PaymentsMethodHeader>
                  <p>Pagar na entrega</p>
                  {restaurantDeliveryTax && restaurantDeliveryTax > 0 ? (
                    <DeliveryTax>
                      {`Taxa de entrega: R$ ${restaurantDeliveryTax}`}
                    </DeliveryTax>
                  ) : (
                    <DeliveryTax>Entrega grátis</DeliveryTax>
                  )}
                </PaymentsMethodHeader>

                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments
                    .filter(it => it.restaurant_method[0].delivery_accepts)
                    .filter(
                      item =>
                        item.keyword !== 'pix_auto' &&
                        item.keyword !== 'credit_card_auto',
                    )
                    .map(method => (
                      <Payment
                        key={uuid()}
                        onClick={e => {
                          handleNavigate(
                            e.currentTarget,
                            'delivery',
                            method.keyword,
                            method.id,
                            method.name,
                          );
                        }}
                        id={method.name}
                      >
                        <div>
                          <img
                            src={getPaymentImage(method.keyword)}
                            alt={method.name}
                          />
                        </div>

                        <p>{method.name}</p>
                      </Payment>
                    ))}
              </>
            )}

          {restaurantAcceptsWithdrawal.toString() === 'true' &&
            restaurantAcceptsPaymentAtWithdrawal.toString() === 'true' &&
            userDeliveryType.toString() === 'withdrawal' &&
            isScheduling.toString() === 'false' && (
              // userDeliveryType.toString() === 'withdrawal' &&

              <>
                <PaymentsMethodHeader>
                  <p>Pagar na retirada</p>
                </PaymentsMethodHeader>

                {/* <Payment
                  onClick={e => {
                    handleNavigate(e.currentTarget, 'withdrawal');
                  }}
                  id="withdrawal"
                >
                  <div>
                    <img src={logodinheiro} alt="logodinheiro" />
                  </div>

                  <p>Pagar na retirada</p>
                </Payment> */}

                {restaurantDeliveryPayments &&
                  restaurantDeliveryPayments.length > 0 &&
                  restaurantDeliveryPayments
                    .filter(it => it.restaurant_method[0].withdrawal_accepts)
                    .filter(
                      item =>
                        item.keyword !== 'pix_auto' &&
                        item.keyword !== 'credit_card_auto',
                    )
                    .map(method => (
                      <Payment
                        key={uuid()}
                        onClick={e => {
                          handleNavigate(
                            e.currentTarget,
                            'withdrawal',
                            method.keyword,
                            method.id,
                            method.name,
                          );
                        }}
                        id={method.name}
                      >
                        <div>
                          <img
                            src={getPaymentImage(method.keyword)}
                            alt={method.name}
                          />
                        </div>

                        <p>{method.name}</p>
                      </Payment>
                    ))}
              </>
            )}
        </PaymentsMethods>
      </Content>
    </Container>
  );
};

export default ChoosePayments;
